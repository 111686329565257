<template>
  <div>
    <!-- <NuxtLoadingIndicator color="black" /> -->
    <vPreNav />
    <vNav />
    <main>
      <div class="wrapper">
        <aside>
          <RouterLink to="/profil">
            Podešavanje naloga
          </RouterLink>
          <RouterLink to="/profil/adrese">
            Adrese
          </RouterLink>
          <RouterLink to="/profil/porudzbine">
            Moje porudžbine
          </RouterLink>
          <RouterLink to="/profil/lista-zelja">
            Lista želja
          <!-- <span v-if="auth.countWishlist !== 0" class="count">
            {{ auth.countWishlist }}
          </span> -->
          </RouterLink>
        </aside>
        <slot />
      </div>
    </main>
    <vFooter />
    <vPostFooter />
    <vToasts />
    <vSupport />
    <vCart />
  </div>
</template>

<style scoped>
.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: calc(220px * 2 + 24px) auto;
  gap: 24px;
}

aside {
  & a {
    display: block;
    font-size: 14px;
    padding: 4px 0;
    color: rgb(0 0 0);

    &.router-link-exact-active {
      color: rgb(0 0 0 / .5);

      & .count {
        color: rgb(0 0 0 / .5);
      }
    }
  }
}
</style>
